import { useState } from 'react';

import { BindType } from '@/types/chatItem';
import { ChatContextType } from '@/types/context/chat.context';

export const useChatContext = (): ChatContextType => {
	const [bindId, setBindId] = useState<string>(null);
	const [bindType, setBindType] = useState<BindType>(null);
	const [bindTitle, setBindTitle] = useState<string>(null);
	const [receiverId, setReceiverId] = useState<string>(null);
	const [receiverName, setReceiverName] = useState<string>(null);

	const resetChatDefault = () => {
		setBindId(null);
		setBindType(null);
		setBindTitle(null);
		setReceiverId(null);
		setReceiverName(null);
	};

	return {
		bindId,
		updateBindId: setBindId,

		bindType,
		updateBindType: setBindType,

		bindTitle,
		updateBindTitle: setBindTitle,

		receiverId,
		updateReceiverId: setReceiverId,

		receiverName,
		updateReceiverName: setReceiverName,

		resetChatDefault,
	};
};
