import { createContext } from 'react';

import { BindType } from '@/types/chatItem';
import { ChatContextType } from '@/types/context/chat.context';

export const ChatContext = createContext<ChatContextType>({
	bindId: null,
	updateBindTitle: function (bindTitle: string) {
		this.bindTitle = bindTitle;
	},

	bindType: null,
	updateBindType: function (bindType: BindType) {
		this.bindType = bindType;
	},

	bindTitle: null,
	updateBindId: function (bindId: string) {
		this.bindId = bindId;
	},

	receiverId: null,
	updateReceiverId: function (receiverId: string) {
		this.receiverId = receiverId;
	},

	receiverName: null,
	updateReceiverName: function (receiverName: string) {
		this.receiverName = receiverName;
	},
	resetChatDefault: function () {},
});
