import { DocsLinks } from '@/types/legal';
import { Metadata } from '@/types/metadata';

export const DEFAULT_NOT_SELECTED_VALUE = -1;
export const DATE_FORMAT_SHORT = 'DD.MM.YYYY';

export const DEFAULT_DOCS_LINKS: DocsLinks = {
	termOfCookie: '',
	termOfUse: '',
	privacyPolicy: '',
	userAgreement: '',
};

export const DEFAULT_META_IMAGE_URL = 'https://fishplace.ru/img/default.jpg';

export const DEFAULT_META_DATA: Metadata = {
	title: 'FishPlace: легко покупайте и продавайте рыбу оптом',
	description:
		'FishPlace — международный цифровой оптовый рынок — логистика и торговля по всей России и на экспорт.',
	keywords: 'рыба, склад, морепродукт, купить рыбу, продать рыбу, рыбный склад',
	additionalTags: [],
	imageUrl: DEFAULT_META_IMAGE_URL,
};

export const NEW = '__new__';

export const AvoidRedirectList = ['login', 'registration', 'register-storage'];
