import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import { NextPage } from 'next';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import NextProgress from 'nextjs-progressbar';

import { ConfigProvider, message, Modal, notification } from 'antd';
import dayjs from 'dayjs';
import cookies from 'js-cookie';
import isBetween from 'dayjs/plugin/isBetween';

import { getMetadata } from '@/api/get-metadata';
import { RequestService } from '@/api/services/request.service';
import { TOKEN_COOKIE_NAME } from '@/auth/constants';
import { AppInfo } from '@/components/AppInfo/AppInfo';
import { Chat } from '@/components/Chat/Chat';
import { CookiesPopup } from '@/components/CookiesPopup';
import { DemoHeader } from '@/components/DemoHeader/DemoHeader';
import { FullScreenLoader } from '@/components/FullScreenLoader/FullScreenLoader';
import Header from '@/components/Header';
import { HeadWithMetaData } from '@/components/HeadWithMetadData';
import { OutdatedBrowserModal } from '@/components/OutdatedBrowserModal';
import { DEFAULT_META_DATA } from '@/constants/common';
import { Environment } from '@/constants/environments';
import { Locale } from '@/constants/locale';
import { ChatContext } from '@/context/chat.context';
import { FeedbackContext } from '@/context/feeadback.context';
import { UserContext } from '@/context/user.context';
import useAsyncEffect from '@/hooks/use-async-effect';
import { useDocLinks } from '@/hooks/use-doc-links';
import useMobile from '@/hooks/use-mobile';
import { useUserContext } from '@/hooks/use-user-context';
import { useChatContext } from '@/hooks/useChatContext';
import { store } from '@/store/store';
import { Metadata } from '@/types/metadata';
import { buildPageUrl } from '@/utils/url';

import { AntConfig } from '../../ant.config';

import '@/styles/style.scss';
import '@/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-chat-elements/dist/main.css';

dayjs.extend(isBetween);

export type NextPageWithLayout<P = NonNullable<unknown>, IP = P> = NextPage<P, IP> & {
	getLayout?: (page: ReactElement) => ReactNode;
};

type AppExtraProps = {
	Component?: NextPageWithLayout;
};

export default function CustomApp({ Component, pageProps }: AppProps & AppExtraProps) {
	const getLayout = Component.getLayout ?? ((page) => page);

	RequestService.getInstance();
	const token = cookies.get(TOKEN_COOKIE_NAME);
	if (token) {
		RequestService.setAuthHeader(token);
	}

	const router = useRouter();
	const [modal, modalContextHolder] = Modal.useModal();
	const [notify, notifyContextHolder] = notification.useNotification({
		placement: 'topRight',
	});
	const [messageInstance, messageContextHolder] = message.useMessage();
	const [metaData, setMetaData] = useState<Metadata>({ ...DEFAULT_META_DATA });
	const [isShownDemoBanner, setIsShownDemoBanner] = useState(false);
	const docsLinks = useDocLinks();
	const { userContext, updateUser, isLogged } = useUserContext();
	const chatContext = useChatContext();
	const isMobile = useMobile();

	const isLocal = globalThis.location?.href?.includes?.('localhost');

	RequestService.addInterceptor();

	useEffect(() => {
		setIsShownDemoBanner(process.env.ENVIRONMENT === 'demo');
	}, []);

	useAsyncEffect(async () => {
		const pageUrl = buildPageUrl(router.asPath, router.locale as Locale);
		const metadata = await getMetadata(pageUrl);
		setMetaData(metadata);
	}, [router.pathname, router.locale]);

	if (userContext.initialDataLoading) {
		return <FullScreenLoader />;
	}

	return (
		<Provider store={store}>
			<ConfigProvider theme={AntConfig}>
				<UserContext.Provider value={{ ...userContext, updateUser }}>
					<FeedbackContext.Provider value={{ modal, notify, message: messageInstance }}>
						<ChatContext.Provider value={chatContext}>
							{modalContextHolder}
							{notifyContextHolder}
							{messageContextHolder}
							<HeadWithMetaData metadata={metaData} />
							<NextProgress color="#4587e8" options={{ showSpinner: false }} />
							{isShownDemoBanner && <DemoHeader />}
							<Header />
							{docsLinks && <CookiesPopup docsLinks={docsLinks} />}
							<OutdatedBrowserModal />
							{getLayout(<Component {...pageProps} docs={docsLinks} />)}
							{isLogged() && <Chat defaultOpen={false} />}
						</ChatContext.Provider>
					</FeedbackContext.Provider>
				</UserContext.Provider>
			</ConfigProvider>
			{(process.env.ENVIRONMENT === Environment.Test || isLocal) && <AppInfo />}
		</Provider>
	);
}
